import React, { useState } from "react";
import { Link } from "gatsby";
import {
  Row,
  Col,
  Container,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/Layout/SEO";

import BottomCTA from "../sections/camelo/BottomCTA";
import ROICalc from "../sections/camelo/pricing/ROICalc";

import imgAmelia from "./../assets/image/customers/Amelie White.png";
import imgStars from "./../assets/image/svg/five-stars-rating.svg";

const QA = ({ id, question, children }) => {
  const [openItem, setOpenItem] = useState(false);

  return (
    <div className="border rounded-10 mb-3 bg-white overflow-hidden">
      <a
        className="mb-0 btn py-8 pl-9 pr-7 border-bottom-0 bg-white accordion-trigger arrow-icon w-100"
        id={"heading-question-" + id}
        aria-controls={"collapse-" + id}
        onClick={() => setOpenItem(!openItem)}
        aria-expanded={openItem}
      >
        <div className="gr-text-7 font-weight-bold text-left text-blackish-blue p-0 ">
          {question}
        </div>
      </a>
      <Collapse in={openItem}>
        <div>
          <div className="card-body gr-color-blackish-blue-opacity-7 pt-0 pl-9 pr-15 gr-text-9 pb-9">
            <div className="pt-6">{children}</div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
const TierContainer = ({
  tierName,
  description,
  monthlyPrice,
  yearlyPrice,
  link,
  image,
  buttonText,
  isMonthly,
  children,
}) => {
  return (
    <>
      {image ? (
        <Col lg="3" md="10" sm="10" className="mb-9 mt-xl-24">
          <div className="pricing-card bg-white border text-left pb-9 bg-white rounded-10 overflow-hidden">
            <div className="price-content">
              <div className="pt-9 pr-9 pr-xl-13  pl-9 pl-xl-13">
                <span className="small-title gr-text-6 text-black font-weight-bold text-uppercase">
                  {tierName}
                </span>
                <p className="gr-text-11 pt-4" style={{ height: "60px" }}>
                  {description}
                </p>
                <div className="d-flex align-items-end mt-11">
                  <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                    &nbsp;
                  </span>
                  <h2 className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0  text-blackish-blue">
                    {image}
                    &nbsp;
                    <span className="d-none">.</span>
                  </h2>
                </div>
                <span className="price-bottom-text gr-text-11 mt-5 text-blackish-blue gr-opacity-7 d-inline-flex">
                  &nbsp;
                </span>
              </div>

              <div className="py-10 border-bottom pr-9 pr-xl-13  pl-9 pl-xl-13">
                <Link
                  to={link}
                  className="btn btn-primary with-icon gr-hover-y px-xl-8 px-lg-4 px-sm-8 px-4"
                >
                  {buttonText}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>

              {children}
            </div>
          </div>
        </Col>
      ) : (
        <Col lg="3" md="10" sm="10" className="mb-9 mt-xl-24">
          <div className="pricing-card bg-white border text-left pb-9 bg-white rounded-10 overflow-hidden">
            <div className="price-content">
              <div className="pt-9 pr-9 pr-xl-13  pl-9 pl-xl-13">
                <span className="small-title gr-text-6 text-black font-weight-bold text-uppercase">
                  {tierName}
                </span>
                <p
                  className="gr-text-11 pt-4"
                  style={{
                    height: "60px",
                  }}
                >
                  {description}
                </p>
                <div className="d-flex align-items-end mt-11 ">
                  <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                    $
                  </span>
                  <h2 className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0 text-blackish-blue">
                    {isMonthly ? monthlyPrice : yearlyPrice}
                    <span className="d-none">.</span>
                  </h2>
                </div>
                <span className="price-bottom-text gr-text-11 mt-5 text-blackish-blue gr-opacity-7 d-inline-flex">
                  {" "}
                  per user per month,{" "}
                  {isMonthly ? "billed monthly" : "billed yearly"}
                </span>
              </div>

              <div className="py-10 border-bottom pr-9 pr-xl-13  pl-9 pl-xl-13">
                <Link
                  to={link}
                  target="__blank"
                  className="btn btn-primary with-icon gr-hover-y px-xl-8 px-lg-4 px-sm-8 px-4"
                >
                  {buttonText}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
                <span className="d-none btn-bottom-text gr-text-11 text-blackish-blue gr-opacity-7 mt-5">
                  <small> No credit card required</small>
                </span>
              </div>
              {children}
            </div>
          </div>
        </Col>
      )}
    </>
  );
};
const Feature = ({ id, name, isFeature = true }) => {
  return (
    <>
      {isFeature ? (
        <li className=" border-bottom mb-3 gr-text-11 font-weight-lighter ">
          <i className={"icon icon-check-simple"}></i>
          {name}
        </li>
      ) : (
        <li className=" border-bottom mb-3 gr-text-11 font-weight-lighter ">
          <i className={"icon icon-simple-remove"} style={{ color: "red" }}></i>
          {name}
        </li>
      )}
    </>
  );
};

const FAQ = ({ annualDiscountRate }) => {
  return (
    <div className="pt-24 pt-lg-32 pb-9 pb-lg-22">
      <Container>
        <Row className="justify-content-center">
          <Col xl="8" lg="9">
            <div className="px-md-12 text-center mb-9 mb-lg-18">
              <h2 className="title gr-text-4 mb-8">
                Frequently Asked Questions
              </h2>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xl="9" lg="9">
            <div className="accordion" id="accordionExample">
              <QA id="2" question="Is there a free version of Camelo?">
                Yes, it is the BASIC plan that contains most of our features. If
                you’d like more premium features, please consider upgrading to
                our PRO plan.
              </QA>
              <QA id="3" question="Do you offer discounts?">
                Yes. When you pay for a year up front, you get a{" "}
                <strong>{annualDiscountRate}% discount</strong>. We also offer
                discounts for non-profits and NGOs.
              </QA>
              <QA id="4" question="What if we need a longer trial?">
                We’re happy to give you some more time. Just 
                <a href="mailto:hello@camelohq.com">let us know</a> and we'll
                extend your trial.
              </QA>
              <QA id="5" question="Will we be charged when our trial is up?">
                <strong>No</strong>. We don't ask for your credit card up front,
                so you'll only be charged when you decide you’re ready. If you
                want to continue after your trial, we'll ask for payment
                details. If not — cancel with a click, no questions asked.
              </QA>
              <QA id="6" question="Can we cancel at any time?">
                <strong>Yes</strong>. You can cancel at any time without penalty
                or hassle. If you prepaid for a year, we’ll refund any unused
                months.
                <br />
                See our{" "}
                <a href="/policies/cancellation" target="_blank">
                  Cancellation Policy
                </a>{" "}
                and{" "}
                <a href="/policies/refund" target="_blank">
                  Fair Refund Policy
                </a>{" "}
                for more details.
              </QA>
              <QA id="7" question="Can I change my plan?">
                You can make changes to your plan at any time. To change your
                plan, simply go into the Billing section, pick the desired plan,
                and enjoy!
              </QA>
              <QA id="8" question="What are the payment options?">
                Currently we accept Visa, Mastercard, American Express, and
                PayPal. At this time we only accept payments online.
              </QA>
              <QA
                id="9"
                question="How do I become an official Camelo reseller?"
              >
                <a href="/contact">Contact us</a> if you are interested in
                becoming an official Camelo reseller for the Pro and Enterprise
                plans.
              </QA>
              <QA id="10" question="Terms of service and privacy policy?">
                Here are our{" "}
                <a href="/legal" target="_blank">
                  policies
                </a>
                .
              </QA>
            </div>
          </Col>
          <div className="col-12 text-center pt-11">
            <p className="gr-text-9 gr-text-9 gr-text-color">
              Haven’t got your answer?{" "}
              <Link to="/contact" className="btn-link">
                Contact our support now.
              </Link>
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
};

const PricingPage = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [annualDiscountRate, setAnnualDiscountRate] = useState(17);

  return (
    <>
      <PageWrapper>
        <SEO
          title="Camelo Pricing | Compare Different Camelo Plans"
          description="Pricing plans for different business sizes, whether you manage a
            small business or a larger company. Enjoy a free 14-day trial of all
            premium features."
        ></SEO>
        <div className="inner-banner bg-default-1 pt-25 pt-lg-29 h-screen">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center mb-5 mb-lg-13">
                  <h2 className="title gr-text-4 mb-6 mb-lg-6">
                    Start a <span className="text-primary">free</span> 14-day
                    trial of all features
                  </h2>
                  <p className="gr-text-8 mb-9">
                    No credit card needed, cancel anytime, 24/7 support
                  </p>
                  <p>
                    <Link
                      onClick={() => {
                        if (
                          typeof window.gtag_report_conversion === "function"
                        ) {
                          window.gtag_report_conversion(process.env.SIGNUP_URL);
                        }
                      }}
                      to={process.env.SIGNUP_URL}
                      className="btn btn-primary with-icon gr-hover-y"
                      target="__blank"
                    >
                      Start My Free Trial
                      <i className="icon icon-tail-right font-weight-bold"></i>
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="pricing-section bg-default-9 py-7 py-lg-14">
          <Container fluid className="px-4 px-lg-15">
            <div className="text-center">
              <div className="justify-content-end d-flex">
                <div
                  className="mb-8 d-inline-flex position-relative align-items-center"
                  id="pricing-dynamic-deck--head"
                >
                  <span
                    className={`period month gr-text-11 ${
                      isMonthly ? "gr-text-color" : "text-muted"
                    } `}
                  >
                    Monthly
                  </span>
                  <a
                    href="/#"
                    className={`btn-toggle mx-3 price-deck-trigger ${
                      isMonthly ? "" : "active"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMonthly(!isMonthly);
                    }}
                  >
                    <span className="round"></span>
                  </a>
                  <span
                    className={`period year gr-text-11 ${
                      isMonthly ? "text-muted" : "gr-text-color"
                    }`}
                  >
                    Yearly
                  </span>
                  <span
                    className={`${
                      isMonthly ? "text-linethrough" : ""
                    } badge gr-badge text-primary gr-text-13 gr-bg-blue-opacity-1 rounded-pill ml-5 text-uppercase`}
                  >
                    Save {annualDiscountRate}%
                  </span>
                </div>
              </div>

              <Row className="justify-content-center align-items-stretch">
                <TierContainer
                  tierName="Free"
                  description="For smaller teams with limited needs"
                  monthlyPrice="0"
                  yearlyPrice="0"
                  link={process.env.SIGNUP_URL}
                  buttonText="Get started"
                  isMonthly={isMonthly}
                >
                  {" "}
                  <>
                    <div className="pt-4 pt-lg-8 pr-9 pr-xl-13  pl-9 pl-xl-13">
                      <ul className="card-list list-style-check p-0">
                        <li className="gr-text-9">
                          <strong>Features:</strong>
                        </li>

                        <Feature
                          id="basic-scheduling"
                          name="Basic Employee Scheduling"
                        />
                        <Feature
                          id="basic-team-messaging"
                          name="Basic Team Communication"
                        />
                        <Feature
                          id="basic-time-clock"
                          name="Time &amp; Attendance"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-timesheets-management"
                          name="Timesheets Management"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-pto-management"
                          name="PTO & Availability Management"
                          isFeature={false}
                        />

                        <Feature
                          id="basic-role-permissions"
                          name="Role Permissions"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-branding-customization"
                          name="Branding Customization"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-task-customization"
                          name="Task Management"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-shift-templates"
                          name="Shift Templates"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-recurring-shifts"
                          name="Recurring Shifts"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-shift-approval"
                          name="Open Shifts Approval"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-shift-trading"
                          name="Shift Trading (swap/offer)"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-shift-batching"
                          name="Shift Batching"
                          isFeature={false}
                        />
                      </ul>
                    </div>

                    <div className=" pr-9 pr-xl-13  pl-9 pl-xl-13">
                      <ul className="card-list list-style-check p-0">
                        <li className="gr-text-9">
                          <strong>Analytics & Reporting</strong>
                        </li>

                        <Feature
                          id="basic-reports"
                          name="Reports"
                          isFeature={false}
                        />
                      </ul>
                    </div>

                    <div className="pr-9 pr-xl-13  pl-9 pl-xl-13">
                      <ul
                        className="pt-3 card-list list-style-check p-0"
                        style={{ borderTop: "1px dotted #fff" }}
                      >
                        <li className="gr-text-9">
                          <strong>Usage </strong>
                        </li>
                        <Feature id="basic-members-amount" name="5 members" />
                        <Feature
                          id="basic-data-storage"
                          name="1 GB storage space"
                        />
                        <Feature id="basic-schedule-amount" name="1 schedule" />
                        <Feature id="basic-job-sites" name="1 job site" />
                        <Feature
                          id="basic-timesheet-and-reporting"
                          name="Historical timesheets and reports"
                          isFeature={false}
                        />
                      </ul>
                    </div>

                    <div className="pr-9 pr-xl-13  pl-9 pl-xl-13">
                      <ul
                        className="pt-3 card-list list-style-check p-0"
                        style={{ borderTop: "1px dotted #fff" }}
                      >
                        <li className="gr-text-9">
                          <strong>Support </strong>
                        </li>
                        <Feature
                          id="basic-onboarding-tour"
                          name="1:1 onboarding tour with our team"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-email-support"
                          name="Email Support"
                          isFeature={false}
                        />
                        <Feature
                          id="basic-priority-support"
                          name="First-in-line 24/7 Priority Support"
                          isFeature={false}
                        />
                      </ul>
                    </div>
                  </>
                </TierContainer>

                <TierContainer
                  tierName="Scheduling"
                  description="For teams that need advanced scheduling features"
                  monthlyPrice="2.9"
                  yearlyPrice="2.5"
                  link={process.env.SIGNUP_URL}
                  isMonthly={isMonthly}
                  buttonText="Get started"
                >
                  <>
                    <div className="pt-4 pt-lg-8 pr-9 pr-xl-13  pl-9 pl-xl-13">
                      <ul className="card-list list-style-check p-0">
                        <li className="gr-text-9">
                          <strong>Features:</strong>
                        </li>

                        <Feature
                          id="scheduling-scheduling"
                          name="Advanced Employee Scheduling"
                        />
                        <Feature
                          id="basic-team-messaging"
                          name="Basic Team Communication"
                        />
                        <Feature
                          id="scheduling-time-clock"
                          name="Time &amp; Attendance"
                          isFeature={false}
                        />
                        <Feature
                          id="scheduling-timesheets-management"
                          name="Timesheets Management"
                          isFeature={false}
                        />
                        <Feature
                          id="scheduling-pto-management"
                          name="PTO & Availability Management"
                        />

                        <Feature
                          id="scheduling-role-permissions"
                          name="Role Permissions"
                        />
                        <Feature
                          id="scheduling-branding-customization"
                          name="Branding Customization"
                        />
                        <Feature
                          id="scheduling-task-management"
                          name="Task Management"
                        />
                        <Feature
                          id="scheduling-shift-templates"
                          name="Shift Templates"
                        />
                        <Feature
                          id="scheduling-recurring-shifts"
                          name="Recurring Shifts"
                        />
                        <Feature
                          id="scheduling-shift-approval"
                          name="Open Shifts Approval"
                        />
                        <Feature
                          id="scheduling-shift-trading"
                          name="Shift Trading (swap/offer)"
                        />
                        <Feature
                          id="scheduling-shift-batching"
                          name="Shift Batching"
                        />
                      </ul>
                    </div>

                    <div className=" pr-9 pr-xl-13  pl-9 pl-xl-13">
                      <ul className="card-list list-style-check p-0">
                        <li className="gr-text-9">
                          <strong>Analytics & Reporting</strong>
                        </li>

                        <Feature
                          id="scheduling-reports"
                          name="Advanced Reports"
                        />
                      </ul>
                    </div>

                    <div className="pr-9 pr-xl-13  pl-9 pl-xl-13">
                      <ul
                        className="pt-3 card-list list-style-check p-0"
                        style={{ borderTop: "1px dotted #fff" }}
                      >
                        <li className="gr-text-9">
                          <strong>Usage </strong>
                        </li>
                        <Feature
                          id="scheduling-members-amount"
                          name="Unlimited members"
                        />
                        <Feature
                          id="scheduling-data-storage"
                          name="Unlimited storage space"
                        />
                        <Feature
                          id="scheduling-schedule-amount"
                          name="Unlimited schedules"
                        />
                        <Feature
                          id="scheduling-job-sites"
                          name="Unlimited job sites"
                        />
                        <Feature
                          id="scheduling-timesheet-and-reporting"
                          name=" Unlimited historical timesheets and reports"
                        />
                      </ul>
                    </div>

                    <div className="pr-9 pr-xl-13  pl-9 pl-xl-13">
                      <ul
                        className="pt-3 card-list list-style-check p-0"
                        style={{ borderTop: "1px dotted #fff" }}
                      >
                        <li className="gr-text-9">
                          <strong>Support </strong>
                        </li>
                        <Feature
                          id="scheduling-onboarding-tour"
                          name="1:1 onboarding tour with our team"
                        />
                        <Feature
                          id="scheduling-email-support"
                          name="Email Support"
                        />
                        <Feature
                          id="scheduling-priority-support"
                          name="First-in-line 24/7 Priority Support"
                        />
                      </ul>
                    </div>
                  </>
                </TierContainer>

                <Col lg="3" md="10" sm="10" className="mb-9 mt-0">
                  <div
                    className="pricing-card suggestion-plan position-relative text-left pb-9 rounded-10 overflow-hidden"
                    style={{ backgroundColor: "#ffe01b" }}
                  >
                    <div
                      className="position-absolute top-0 w-100 text-center py-7"
                      style={{ backgroundColor: "#ffe01b", color: "black" }}
                    >
                      <div className="gr-text-8">
                        <strong>
                          <span role="img" aria-label="hi">
                            🌟
                          </span>{" "}
                          Most Popular{" "}
                          <span role="img" aria-label="hi">
                            🌟
                          </span>
                        </strong>
                      </div>
                      <div className="gr-opacity-6 pt-1">
                        Chosen by 85% of businesses
                      </div>
                    </div>

                    <div className="price-content mt-24 bg-white rounded-top-5 h-100">
                      <div className="pt-9 pr-9 pr-xl-13  pl-9 pl-xl-13 ">
                        <span className="small-title gr-text-6 text-primary font-weight-bold text-uppercase">
                          Pro
                        </span>
                        <p
                          className=" gr-text-11 pt-4 text-black"
                          style={{
                            height: "60px",
                          }}
                        >
                          Advanced scheduling, time clocks and communication for
                          teams that need to streamline their business
                        </p>
                        <div className="d-flex align-items-end mt-15 mt-lg-11 ">
                          <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                            $
                          </span>
                          <h2 className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0 text-blackish-blue">
                            {isMonthly ? "3.5" : "3"}
                            <span className="d-none">.</span>
                          </h2>
                        </div>
                        <span className="price-bottom-text gr-text-11 mt-5 text-blackish-blue gr-opacity-7 d-inline-flex">
                          {" "}
                          per user per month,{" "}
                          {isMonthly ? "billed monthly" : "billed yearly"}
                        </span>
                      </div>

                      <div className="py-10 border-bottom pr-9 pr-xl-13  pl-9 pl-xl-13">
                        <Link
                          onClick={() => {
                            if (
                              typeof window.gtag_report_conversion ===
                              "function"
                            ) {
                              window.gtag_report_conversion(
                                process.env.SIGNUP_URL,
                              );
                            }
                          }}
                          to={process.env.SIGNUP_URL}
                          target="__blank"
                          className="btn btn-primary with-icon gr-hover-y px-xl-8 px-lg-4 px-sm-8 px-4"
                        >
                          Try for Free
                          <i className="icon icon-tail-right font-weight-bold"></i>
                        </Link>
                        <span className="d-none btn-bottom-text gr-text-11 text-blackish-blue gr-opacity-7 mt-5">
                          <small> No credit card required</small>
                        </span>
                      </div>

                      <div className="pt-4 pt-lg-8 pr-9 pr-xl-13  pl-9 pl-xl-13">
                        <ul className="card-list list-style-check p-0">
                          <li className="gr-text-9">
                            <strong>Features:</strong>
                          </li>

                          <Feature
                            id="pro-scheduling"
                            name="Advanced Employee Scheduling"
                          />
                          <Feature
                            id="pro-team-messaging"
                            name="Advanced Team Communication"
                          />
                          <Feature
                            id="pro-time-clock"
                            name="Time &amp; Attendance"
                          />
                          <Feature
                            id="pro-timesheets-management"
                            name="Timesheets Management"
                          />
                          <Feature
                            id="pro-pto-management"
                            name="PTO & Availability Management"
                          />

                          <Feature
                            id="pro-role-permissions"
                            name="Role Permissions"
                          />
                          <Feature
                            id="pro-branding-customization"
                            name="Branding Customization"
                          />
                          <Feature
                            id="pro-branding-customization"
                            name="Task Management"
                          />
                          <Feature
                            id="pro-shift-templates"
                            name="Shift Templates"
                          />
                          <Feature
                            id="pro-recurring-shifts"
                            name="Recurring Shifts"
                          />
                          <Feature
                            id="pro-shift-approval"
                            name="Open Shifts Approval"
                          />
                          <Feature
                            id="pro-shift-trading"
                            name="Shift Trading (swap/offer)"
                          />
                          <Feature
                            id="pro-shift-batching"
                            name="Shift Batching"
                          />
                        </ul>
                      </div>
                      <div className=" pr-9 pr-xl-13  pl-9 pl-xl-13">
                        <ul className="card-list list-style-check p-0">
                          <li className="gr-text-9">
                            <strong>Analytics & Reporting</strong>
                          </li>

                          <Feature id="pro-reports" name="Advanced Reports" />
                        </ul>
                      </div>

                      <div className="pr-9 pr-xl-13  pl-9 pl-xl-13">
                        <ul
                          className="pt-3 card-list list-style-check p-0"
                          style={{ borderTop: "1px dotted #fff" }}
                        >
                          <li className="gr-text-9">
                            <strong>Usage </strong>
                          </li>
                          <Feature
                            id="pro-members-amount"
                            name="Unlimited members"
                          />
                          <Feature
                            id="pro-data-storage"
                            name="Unlimited storage space"
                          />
                          <Feature
                            id="pro-schedule-amount"
                            name="Unlimited schedules"
                          />
                          <Feature
                            id="pro-job-sites"
                            name="Unlimited job sites"
                          />
                          <Feature
                            id="pro-timesheet-and-reporting"
                            name=" Unlimited historical timesheets and reports"
                          />
                        </ul>
                      </div>

                      <div className="pr-9 pr-xl-13  pl-9 pl-xl-13">
                        <ul
                          className="pt-3 card-list list-style-check p-0"
                          style={{ borderTop: "1px dotted #fff" }}
                        >
                          <li className="gr-text-9">
                            <strong>Support </strong>
                          </li>
                          <Feature
                            id="pro-onboarding-tour"
                            name="1:1 onboarding tour with our team"
                          />
                          <Feature
                            id="pro-email-support"
                            name="Email Support"
                          />
                          <Feature
                            id="pro-priority-support"
                            name="First-in-line 24/7 Priority Support"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                <TierContainer
                  tierName="Enterprise"
                  description="For large organizations that need additional security,
                          control, and support."
                  link="/contact"
                  image={
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="0.8em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.285.089A.5.5 0 0115.5.5v15a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V14h-1v1.5a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5v-6a.5.5 0 01.418-.493l5.582-.93V3.5a.5.5 0 01.324-.468l8-3a.5.5 0 01.46.057zM7.5 3.846V8.5a.5.5 0 01-.418.493l-5.582.93V15h8v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V15h2V1.222l-7 2.624z"
                        clip-rule="evenodd"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        d="M6.5 15.5v-7h1v7h-1z"
                        clip-rule="evenodd"
                      ></path>
                      <path d="M2.5 11h1v1h-1v-1zm2 0h1v1h-1v-1zm-2 2h1v1h-1v-1zm2 0h1v1h-1v-1zm6-10h1v1h-1V3zm2 0h1v1h-1V3zm-4 2h1v1h-1V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm-2 2h1v1h-1V7zm2 0h1v1h-1V7zm-4 0h1v1h-1V7zm0 2h1v1h-1V9zm2 0h1v1h-1V9zm2 0h1v1h-1V9zm-4 2h1v1h-1v-1zm2 0h1v1h-1v-1zm2 0h1v1h-1v-1z"></path>
                    </svg>
                  }
                  buttonText="Let's talk"
                >
                  <div className="pt-4 pt-lg-8 pr-9 pr-xl-13  pl-9 pl-xl-13">
                    <ul className="card-list list-style-check p-0">
                      <li className="gr-text-9">
                        <strong>Includes Pro, plus:</strong>
                      </li>
                      <li className="gr-text-9">
                        <i className="icon icon-check-simple"></i>Custom
                        implementation
                      </li>
                      <li className="gr-text-9">
                        <i className="icon icon-check-simple"></i>Custom roles &
                        permissions
                      </li>
                      <li className="gr-text-9">
                        <i className="icon icon-check-simple"></i>High volume
                        usage
                      </li>
                      <li className="gr-text-9">
                        <i className="icon icon-check-simple"></i>API access to
                        build your own integrations
                      </li>
                    </ul>
                  </div>
                </TierContainer>
              </Row>
            </div>

            <div
              style={{}}
              className="content-img img-group-2 rounded-xl py-12 mt-16 border-top border-gray-3 text-left d-flex align-items-center justify-content-start "
            >
              <div className="gr-hover-zoom-img">
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Hi, I'm Abigail.
                      <br /> Nice to meet you!
                      <span
                        role="img"
                        aria-label="hi"
                        className="wave-animation"
                      >
                        👋
                      </span>
                    </Tooltip>
                  }
                >
                  <img
                    className="zoomable rounded-circle border-white-4 h-auto "
                    style={{ backgroundColor: "#FBDD5B", width: "80px" }}
                    width="80"
                    src={imgAmelia}
                    alt=""
                  />
                </OverlayTrigger>
              </div>
              <figure className="text-end ml-8 my-0">
                <blockquote className="blockquote">
                  <div>
                    <img src={imgStars} style={{ width: "5rem" }} />
                  </div>
                  <p>
                    "Camelo is a perfect balance of simplicity, functionality
                    and budget."
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  Abigail Robertson,{" "}
                  <cite title="Source Title">Business owner</cite>
                </figcaption>
              </figure>
            </div>
          </Container>
        </div>

        <ROICalc />
        <FAQ annualDiscountRate={annualDiscountRate} />

        <div className="border-top">
          <BottomCTA />
        </div>
      </PageWrapper>
    </>
  );
};
export default PricingPage;
